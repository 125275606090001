<template>
  <v-dialog
    v-model="dialogProfile"
    @click:outside="$emit('update:dialogProfile', false)"
    @keydown.esc="$emit('update:dialogProfile', false)"
    scrollable
    fullscreen
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-badge-account-horizontal</v-icon>
        {{ equipe_original.nome }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogProfile', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="pa-6" :disable="equipe">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <!-- Logo -->
            <v-col cols="12" md="3">
              <!-- <h1 class="text-h6 font-weight-bold mb-4">Avatar</h1> -->
              <v-card
                outlined
                class="fill-height d-flex align-center justify-center"
                style="position:relative;"
              >
                <v-card flat class="pa-4">
                  <v-img v-if="avatar" :src="avatar" contain></v-img>
                  <v-icon size="200" v-else>mdi-account</v-icon>
                </v-card>

                <v-btn
                  fab
                  dark
                  x-small
                  absolute
                  bottom
                  right
                  @click="onButtonClick"
                >
                  <input
                    accept="image/*"
                    type="file"
                    class="d-none"
                    ref="uploader"
                    @change="onFileChanged"
                  />
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </v-card>
            </v-col>

            <!-- Dados -->
            <v-col cols="12" md="9" class="">
              <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>

              <v-row>
                <!--Nome  -->
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="equipe.nome"
                    label="Nome"
                    dense
                    required
                    :rules="[formRules]"
                  ></v-text-field>
                </v-col>

                <!-- CPF -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-mask="`###.###.###-##`"
                    v-model="equipe.cpf"
                    label="CPF"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- RG -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="equipe.rg"
                    label="RG"
                    dense
                    
                  ></v-text-field>
                </v-col>

                

                <!-- ANIVESARIO -->
                <v-col cols="12" md="4">
                  <DataField
                    label="Data Aniversário"
                    :data_sync.sync="equipe.data_aniversario"
                  />
                </v-col>

                

                

                <!--E-mail  -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="equipe.email"
                    label="E-mail"
                    dense
                  ></v-text-field>
                </v-col>

               

                <!-- telefone -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe.telefone"
                    label="Telefone"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- celular -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe.celular"
                    label="Celular"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- whatsapp -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe.whatsapp"
                    label="Whatsapp"
                    dense
                  ></v-text-field>
                </v-col>

                <!--Telegram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe.telegram"
                    label="Telegram"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Endereco -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
              <v-row>
                <!-- CEP -->

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe.cep"
                    label="CEP"
                    dense
                  ></v-text-field>
                </v-col>

                <!--endereco -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Endereço"
                    v-model="equipe.endereco"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('numero')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Número"
                    v-model="equipe.numero"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('('bairro')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Bairro"
                    v-model="equipe.bairro"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('('complemento')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Complemento"
                    v-model="equipe.complemento"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Cidade -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Cidade"
                    v-model="equipe.cidade"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- UF -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe.uf"
                    label="UF"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Midia -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
              <v-row>
                <!-- Instagram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Instagram"
                    v-model="equipe.instagram"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Facebook -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Facebook"
                    v-model="equipe.facebook"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Linkedin -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Linkedin"
                    v-model="equipe.linkedin"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Youtube -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Youtube"
                    v-model="equipe.youtube"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn @click="$emit('update:dialogProfile', false)" exact outlined>
          Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="updateCliente"   color="secondary" class="white--text">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  fetchClientesEquipes,
  putClientesEquipe,
} from "@/api/cliente/cliente_equipe.js";
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { mapState, mapGetters } from "vuex";


export default {
  name: "ProfileCliente",

  props: {
    dialogProfile: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    DataField: () => import("../../../components/fields/DataField.vue"),
  },

  data() {
    return {
      loading: false,
      validForm: true,
      logoUpload: null,
      avatar: null,
      tab: 0,
      hoverFile: false,
      equipe: {},
      equipe_original: {},
      formRules: inputRequired,
      emailRule: validaEmail,
    };
  },

  computed: {
    ...mapGetters("Usuario", ["isCliente"]),
    ...mapState("Usuario", {
      usuario : (state) => state.usuario,
      user_id : (state) => state.usuario.id,
      
    }),
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      this.avatarUpload = file;
      this.equipe.avatar = !this.equipe.avatar ? "avatar" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
        // vue.editFornecedor();
      };
    },
    getClienteEquipe() {
      if(this.isCliente){
        return fetchClientesEquipes(`?user_id=${this.user_id}`)
        .then((response) => {
          this.equipe = response[0]
          this.avatar = this.equipe.avatar;
          this.equipe_original = { ...this.equipe };
         
        })
        .catch(() => {
          this.$toast.error("Sem Conexão");
          this.loading = false;
        });
      }
      
    },

    updateCliente() {
      if (
        JSON.stringify(this.equipe) ===
        JSON.stringify(this.equipe_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const equipe = new FormData();

        if (this.avatarUpload) {
          equipe.append(
            "avatar",
            this.avatarUpload,
            this.avatarUpload.name
          );
        }

        let equipe_2 = {};
        equipe_2.bairro = this.equipe.bairro;
        equipe_2.data_aniversario = this.equipe.data_aniversario;
        
        equipe_2.celular = this.equipe.celular;
        equipe_2.cep = this.equipe.cep;
        equipe_2.cidade = this.equipe.cidade;
        equipe_2.complemento = this.equipe.complemento;
        equipe_2.cpf = this.equipe.cpf;
        
        equipe_2.email = this.equipe.email;
        equipe_2.endereco = this.equipe.endereco;
        equipe_2.facebook = this.equipe.facebook;
        
        equipe_2.instagram = this.equipe.instagram;
        equipe_2.linkedin = this.equipe.linkedin;
        equipe_2.nome = this.equipe.nome;
        equipe_2.numero = this.equipe.numero;
        equipe_2.rg = this.equipe.rg;
        equipe_2.telefone = this.equipe.telefone;
        equipe_2.telegram = this.equipe.telegram;
        equipe_2.uf = this.equipe.uf;
        equipe_2.whatsapp = this.equipe.whatsapp;
        equipe_2.youtube = this.equipe.youtube;
        equipe_2.status = this.equipe.status;
        for (let key in equipe_2) {
          if (equipe_2[key]) {
            equipe.append(key, equipe_2[key]);
          }
        }
        putClientesEquipe(this.equipe.id, equipe)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Atualizado com sucesso");
              this.loading = false;
              this.$store.dispatch("Usuario/getUsuario");
              this.getClienteEquipe();
              this.$emit('update:dialogProfile', false);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.getClienteEquipe();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
